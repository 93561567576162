var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer-style" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 } },
            [_c("CardItem", { attrs: { title: "总量", num: _vm.num[0] } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 } },
            [_c("CardItem", { attrs: { title: "已分配", num: _vm.num[1] } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 } },
            [_c("CardItem", { attrs: { title: "已使用", num: _vm.num[2] } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 } },
            [_c("CardItem", { attrs: { title: "剩余量", num: _vm.num[3] } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { md: 24, lg: 12 } },
            [
              _c("PieComponent", {
                attrs: {
                  title: "课程包分配统计",
                  id: "echart1",
                  name: "分配",
                  height: 360,
                  colors: ["#73c0de", "#91cc75"],
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 12 } },
            [
              _c("PieComponent", {
                attrs: {
                  title: "课程包使用统计",
                  id: "echart2",
                  name: "使用",
                  height: 360,
                  colors: ["#5470c6", "#f4c857"],
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }