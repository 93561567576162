var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box-style" },
    [
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("el-image", {
                    staticStyle: { width: "80px", height: "80px" },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 14 } }, [
                _c("h3", [_vm._v(_vm._s(_vm.title))]),
                _c("span", [_vm._v(" " + _vm._s(_vm.numToString(_vm.num)))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }