var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-style" }, [
    _c("div", { style: { height: _vm.height + "px" }, attrs: { id: _vm.id } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }