<template>
  <div class="outer-style">
    <el-row>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"> <CardItem title="总量" :num="num[0]" /> </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"> <CardItem title="已分配" :num="num[1]" /> </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"> <CardItem title="已使用" :num="num[2]" /> </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"> <CardItem title="剩余量" :num="num[3]" /> </el-col>
    </el-row>
      <el-row>
        <el-col :md="24" :lg="12">
          <PieComponent
            title="课程包分配统计"
            id="echart1"
            name="分配"
            :height="360"
            :colors="['#73c0de', '#91cc75']"
          />
        </el-col>
        <el-col :md="24" :lg="12">
          <PieComponent
            title="课程包使用统计"
            id="echart2"
            name="使用"
            :height="360"
            :colors="['#5470c6', '#f4c857']"
          />
        </el-col>
      </el-row>
  </div>
</template>
<script>
import CardItem from "./component/CardItem";
import PieComponent from "./component/PieComponent";
export default {
  components: { CardItem, PieComponent },
  data() {
    return {
      num: [14023, 2120, 23, 12343],
    };
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>