<template>
  <div class="box-style">
    <div :id="id" :style="{ height: height + 'px' }"></div>
  </div>
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: () => 300,
    },
    title: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    id: {
      type: String,
      default: () => "",
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pieChart: null,
    };
  },
  mounted() {
    this.pieChart = this.$echarts.init(document.getElementById(this.id));
    this.$nextTick(() => {
      this.initEchart();
    });
  },
  methods: {
    initEchart() {
      const option = {
        title: {
          text: this.title,
          subtext: this.name,
          left: "center",
          top:'20px'
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          top: "50px",
          left: "20px",
        },
        series: [
          {
            name: this.title,
            type: "pie",
            radius: ["40%", "70%"],
            center: ['50%', '60%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 735, name: "已" + this.name },
              { value: 580, name: "未" + this.name },
            ],
          },
        ],
      };
      if (this.colors.length > 0) {
        option.color = this.colors;
      }
      this.pieChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
#main {
  width: 100%;
  background-color: #fff;
}
.box-style {
  margin: 10px;
  background-color: #fff;
  box-sizing: border-box;
}
</style>