<template>
  <div class="box-style">
    <el-card>
      <el-row>
        <el-col :span="10">
          <el-image style="width: 80px; height: 80px"></el-image>
        </el-col>
        <el-col :span="14">
          <h3>{{ title }}</h3>
          <span> {{ numToString(num) }}</span>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: () => "",
    },
    num: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    numToString(str) {
      str = str + "";
      let re = /(?=(?!\b)(\d{3})+$)/g;
      return str.replace(re, ",");
    },
  },
};
</script>
<style lang="scss" scoped>
.box-style {
  padding: 10px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
